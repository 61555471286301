import React, { Component } from 'react'
import IndexHome from '../components/IndexHome'
import IndexIntroduction from '../components/IndexIntroduction'
import Footer from '../components/Footer'
import Scroller from '../components/Scroll'
import Layout from '../layouts'

class IndexPage extends Component {
  state = { whiteLogo: false }
  setHeaderColor = whiteLogo => this.setState({ whiteLogo })
  updateHeaderColor = active => {
    switch (active) {
      case 0:
        return true
      case 1:
        return true
      default:
        return false
    }
  }
  render() {
    const { whiteLogo } = this.state

    return (
      <Layout whiteLogo={whiteLogo}>
        <div style={{ height: '100%', width: '100%' }}>
          <Scroller sections={2}>
            <Scroller.DotGroup
              className="hidden"
              renderDot={({ active }) => {
                const white = this.updateHeaderColor(active)

                if (white !== whiteLogo) {
                  this.setHeaderColor(white)
                }
                return <button />
              }}
            />
            <Scroller.Sections className="sections">
              <Scroller.Section className="section">
                <IndexHome />
              </Scroller.Section>
              <Scroller.Section>
                <IndexIntroduction />
                <Footer />
              </Scroller.Section>
            </Scroller.Sections>
          </Scroller>
          
        </div>
      </Layout>
    )
  }
}
export default IndexPage
