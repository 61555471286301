import React, { Component } from 'react'
import './index_home.css'

class IndexHome extends Component {
  render() {
    return (
      <section id="home">
        <video autoPlay loop muted id="myVideo">
          <source
            src="https://otm.co.in/media_files/Websitev20sec.mp4"
            type="video/mp4"
            style={{
              width: '100%',
              maxWidth: '100%',
              marginTop: '0px',
              overflow: 'hidden',
            }}
          />
        </video>
        <div className="content1">
          <div className="content1-txt">
            <div className="heading-title">
              Fairfest is a leading media company providing platforms for doing
              business in one of the fastest growing markets in the world -{' '}
              <span className="india">India.</span>
            </div>
            <div className="inner-text-heding">
              Since 1989, Fairfest organises industry leading tradeshows in the
              travel and city development space.
              <br />
              Come, partner with Fairfest, and grow your business.
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default IndexHome
