import React from 'react'
import { Link } from 'gatsby'
import './index_Introduction.css'

const IndexIntroduction = () => (
  <section id="about">
    <Link to="/travel-and-tourism">
      <div className="homesection2 col-md-4 col-sm-12" id="home_tt">
        <div className="homesub1 team-box box1">
          <div className="team-title">
            <h3>
              TRAVEL &amp;
              <br />
              TOURISM
            </h3>
            <p>India’s leading travel show network</p>
            <div className="team-img1" />
          </div>
        </div>
      </div>
    </Link>
    <Link to="/urban-development">
      <div className="homesection2 col-md-4 col-sm-12" id="home_ud">
        <div className="homesub2 team-box box2">
          <div className="team-title">
            <h3>
              URBAN
              <br />
              DEVELOPMENT
            </h3>
            <p>A 360-degree event on city development</p>
            <div className="team-img2" />
          </div>
        </div>
      </div>
    </Link>
    <Link to="/technology">
      <div className="homesection2 col-md-4 col-sm-12" id="home_tech">
        <div className="homesub3 team-box box3">
          <div className="team-title">
            <h3>TECHNOLOGY</h3>
            <p>
              Ambitious, world-class technology startups
              <br />
            </p>
            <div className="team-img3" />
          </div>
        </div>
      </div>
    </Link>
  </section>
)

export default IndexIntroduction
